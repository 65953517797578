.ProjectCardMain {
    width: 24.5vw;
    /* background: rgb(5,62,253);
    background: -moz-linear-gradient(274deg, rgba(5,62,253,0.5907679966517857) 5%, rgba(159,53,255,0.6103758397890406) 100%);
    background: -webkit-linear-gradient(274deg, rgba(5,62,253,0.5907679966517857) 5%, rgba(159,53,255,0.6103758397890406) 100%);
    background: linear-gradient(274deg, rgba(5,62,253,0.5907679966517857) 5%, rgba(159,53,255,0.6103758397890406) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#053efd",endColorstr="#9f35ff",GradientType=1); */
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(290%);
    background-color: #f3f3f3bd;
    border: 1.5px solid rgba(143, 141, 141, 0.5);     
    /* box-shadow: 0 2px 10px 0 rgba(62, 0, 38, 0.551); */
    /* margin: 20px; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    border-radius: 8px;
    font-family: 'Outfit', sans-serif;
    min-height: 100%;
    /* overflow: auto; */
}
.ProjectCardMain:hover{
    scale:101.5%;
}
.ProjectCardMain > div > h1 {
    font-weight: 500;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 2rem;
    color: #303030;
}
.ProjectCardMain > div > p {
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 10px;
    margin-bottom: 10px;    
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;
    color: #303030;
}
.projectPreview {
    /* Project Thumbnail Image */
    max-height: 20vw;
    max-width: 20vw;
    margin: 10px;
    margin-top: 20px;
    border: 1.5px solid rgba(61, 61, 61, 0.5);
    border-radius: 8px;
}

.tags{
    display: flex;
    max-height: 2.1rem;
    margin: 15px;
    /* max-width: max-content;
    overflow-y: scroll; */
}

.links{
    margin: 10px;
}

.links > a {
    text-decoration: none;
}
.links > a > img {
    max-height: 45px;
    max-width: 45px;
    margin: 10px;
    background-color: #fff;
    border-radius: 8px;
    padding: 4px;
}
.links > a > img:hover {
    scale:105%;
}

/* For screens up to 450px wide */
@media only screen and (max-width: 450px) {
    .ProjectCardMain {
        width: 70vw;
        margin: 10px;
        min-height: auto;
    }
    .projectPreview {
        max-width: 25vh;
        max-height: 25vh;
    }
    .ProjectCardMain > div > p {
        margin-top: 10px;
        font-size: 0.75rem;
        text-align: left;
    }
    .ProjectCardMain > div > h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.4rem;
    }
}

/* For screens between 450px and 1050px wide */
@media only screen and (min-width: 451px) and (max-width: 1050px) {
    .ProjectCardMain {
        width: 35vw;
        margin: 10px;
    }
    .projectPreview {
        max-width: 30vw;
        max-height: 30vw;
    }
    .ProjectCardMain > div > p {
        margin-top: 10px;
        font-size: 0.75rem;
        text-align: left;
    }
    .ProjectCardMain > div > h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.4rem;
    }
}
