*{
    margin: 0;
    padding: 0;
}
/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700;800&family=Outfit:wght@400;500;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
 */
 @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700;800&family=Outfit:wght@300;400;500;600;700;800&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

::-webkit-scrollbar {
    width: 0.4rem;  
}
  
  /* Track */
::-webkit-scrollbar-track {
    background:transparent;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #38383894;
    border-radius: 2px;
    box-shadow: 0.1rem 0 0.5rem #acacac;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #444444;
}

.App{
    background-color:transparent; /* Background color for the entire page */
    background-image: linear-gradient(90deg, rgba(2, 2, 2, 0.014) 1px, transparent 1px), linear-gradient(rgba(0, 0, 0, 0.011) 1px, transparent 1px);
    background-size: 15px 15px; /* Adjust the grid size */
}