#nav-bar{
  backdrop-filter: blur(13px) saturate(230%);
  -webkit-backdrop-filter: blur(16px) saturate(230%);
  background-color: rgba(215, 215, 215, 0.391);
  border: 1.5px solid rgba(209, 213, 219, 0.5); 
  font-family: 'Outfit',sans-serif;  
  border-radius: 8px;
  font-weight: 500;
  padding-left: 15px;
  /* padding-right: 90px; */
  padding-top:8px;
  padding-bottom: 8px;
  position: sticky;
  z-index: 2;
  top: 0;
  width: 85vw;
  margin-top: 25px;
  margin-left: 7.5vw;
  margin-right: 7.5vw;
  /* margin-bottom: 25px; */
}
@media only screen and (max-width: 450px) {
  #nav-bar{
    top: auto;
  }

}