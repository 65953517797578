.AboutSectionWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
}
.AboutSection{
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(230%);
    background-color: rgba(215, 215, 215, 0.391);
    border: 2px solid rgba(209, 213, 219, 0.5);    
    border-radius: 8px;
    max-width: 85vw;
    text-align: left;
    /* font-family: 'Outfit', sans-serif; */
    font-family: 'Outfit', sans-serif;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #232323;
}

.title {
    color: #232323;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    font-size: 4rem;
}
.AboutSection > p {
    /* font-weight: 500; */
    font-size: 2rem;
    margin-top: 10px;
}

.SkillsSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(230%);
    background-color: rgba(215, 215, 215, 0.391);
    border: 2px solid rgba(209, 213, 219, 0.5);   
    border-radius: 8px;
    width: 85vw;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 30px;
}
.sphereImage{
    /* Image of Hello and skill-bulb */
    max-height: 70px;
    max-width: 70px;
    margin-right: 10px;
}
.skillIcons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 450px) {
    .sphereImage{
        max-height: 37px;
        max-width: 37px;
        margin-right: 10px;
    }
    .AboutSection > p {
        /* font-weight: 500; */
        font-size: 0.97rem;
        margin-top: 10px;
        text-align: start;
    }
    .title {
        font-size: 1.6rem;
    }
}
@media only screen and (min-width: 451px) and (max-width: 1050px) {
    .title{
        font-size: 2.2rem;
    }
    .sphereImage{
        max-height: 37px;
        max-width: 37px;
    }
    .AboutSection > p {
        /* font-weight: 500; */
        font-size: 1.3rem;
    }

}