.SkillsCard{
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(290%);
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(17, 17, 17, 0.5); 
    backdrop-filter: blur( 18px );
    -webkit-backdrop-filter: blur( 18px );
}
.SkillsCard:hover {
    scale: 105%;
}
.SkillsCard > img {
    /* Skill Icon */
    max-width: 58px;
    max-height: 58px;

}
@media only screen and (max-width: 450px) {
    .SkillsCard > img{
        max-height: 25px;
        max-width: 25px;
    }  
}