.ContactSectionMain{
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    max-width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(290%);
    background-color: rgba(215, 215, 215, 0.391);
    border: 2px solid rgba(209, 213, 219, 0.5); 
    border-radius: 8px;
    margin-bottom: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: 'Outfit', sans-serif;
    color: #232323;
}
.heading{
    display: flex;
    align-items: center;
    align-self: center;
    margin-bottom: 32px;
}
.heading > img {
    max-height: 60px;
    max-width: 60px;
    margin-right:10px ;
}
.heading > h1 {
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 4rem;
}
.socialLinkContainer{
    display: flex;
}
.socialBox{
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(290%);
    background-color: #f3f3f3bd;    
    border: 2px solid rgba(209, 213, 219, 0.5);
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
}
.socialBox > img {
    max-width: 45px;
    max-height: 45px;
}
.socialBox > a {
    text-decoration: none;
    color: #232323;
    font-weight: 500;
    margin: 5px;
    /* margin-top: 13px; */
    font-size: 1.3rem;
    text-align: center;
}
.blogBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin: 10px;
}

.blogBox > a > img  {
    max-height: 20vw;
    max-width: 20vw;
    border-radius: 8px;
}

.blogBox > p {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 2px;
}

@media only screen and (max-width: 450px) {
    .socialLinkContainer{
        flex-direction: column;
        align-items: center;
    }
    .socialBox {
        width: 60vw;
    }
    .socialBox > a{
        font-size: 0.7rem;
        /* margin-top: 20px; */
    }
    .h2{
        font-size: 1rem;
        text-align: center;
    }
    .blogBox > a > img{
        max-width: 60vw;
        max-height: 60vw;
    }  
    .heading > img {
        max-height: 37px;
        max-width: 37px;
        margin-right: 10px;
    }
    .heading > h1 {
        font-size: 1.6rem;
    }
}
@media only screen and (min-width: 451px) and (max-width: 1050px) {
    .socialBox {
        width: 20vw;
    }
    .socialBox > a{
        /* margin-top: 20px; */
        font-size: 0.8rem;
    }
    .blogBox > a > img{
        max-width: 40vw;
        max-height: 40vw;
    }  
    .heading > img {
        max-height: 37px;
        max-width: 37px;
        margin-right: 10px;
    }
    .heading > h1 {
        font-size: 2.2rem;
    }
}