.FooterMain{
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    max-width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(13px) saturate(290%);
    -webkit-backdrop-filter: blur(16px) saturate(290%);
    background-color: rgba(215, 215, 215, 0.391);
    border: 2px solid rgba(209, 213, 219, 0.5);   
    border-radius: 8px;
    margin-bottom: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    font-family: 'Outfit', sans-serif;
    color: #232323;
}
.FooterMain > p {
    font-weight: 500;
    margin: 8px;
    text-align: center;
    font-size: 0.96rem;
}
.FooterMain > p > a {
    color: #232323;
}
.endNote{
    font-size: 1.5rem;
}